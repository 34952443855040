<template>
  <v-menu
    attach
    bottom
    left
    :close-on-content-click="false"
    offset-y
    rounded="lg"
  >
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on">
        <v-icon> mdi-dots-vertical </v-icon>
      </v-btn>
    </template>
    <v-card class="content-accion-menu">
      <v-container>
        <v-row>
          <v-col
            cols="12"
            v-for="(item, index) in aMenuAccion"
            :key="index"
            class="content-accion"
          >
            <span @click="goAccion(itemTable, item.action)">
              <span
                ><v-icon :style="{ color: item.color }">{{
                  item.icon
                }}</v-icon></span
              >
              <span :style="{ color: item.color }" class="text-menu-accion">{{
                item.text
              }}</span>
            </span>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </v-menu>
</template>

<script>
export default {
  props: {
    aMenuAccion: Array,
    itemTable: Object,
  },
  data() {
    return {};
  },
  methods: {
    goAccion(item, action) {
      switch (action) {
        case 1:
          this.$emit("openDetailItem", item);

          break;
        case 2:
          this.$emit("openDeleteItem", item);

          break;
        case 3:
          this.$emit("openUpdatePrice", item);

          break;
        case 4:
          this.$emit("openImgsOP", item,false);

          break;
        case 5:
          this.$emit("openWaste", item);

          break;
        case 6:
          this.$emit("openModify", item);

          break;

        default:
          break;
      }
    },
  },    
};
</script>

<style scoped>
.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}
.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}
.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
</style>